export const DA_TEXT = {
  contact: 'Kontakt os',
  mailto: 'info@business-science.dk',
  frontPage: {
    headline: 'Estimér reklameerindringen for dine outdoor-kreativer',
    body: 'Herunder kan du estimere dine outdoor-kreativers effekt på reklameerindringen. Det kan du på baggrund af 300 individuelle reklameerindringsstudier, kampagnernes outdoor kreativ samt machine learning modeller og computer vision. Du kan både teste ét kreativ eller holde to forskellige op i mod hinanden i.',
    startBtnLabel: 'Beregn reklameerindringen',
  },
  typeSwitch: {
    left: 'Test ét kreativ',
    right: 'Test to kreativer',
  },
  dropdowns: {
    image: {
      errorText: 'Angiv kampagne kreativ',
      title: 'Tilføj kampagne kreativ',
      type: 'Filformat/ –dimensioner: jpg, png, etc/9:16',
      info: { head: 'Kampagne kreative', body: 'Tilføj kampagnes OOH kreativ. Der kan kun vælges et kreativ.' },
    },
    industry: {
      errorText: 'Angiv branche',
      title: 'Angiv branche',
      prefix: '',
      data: [
        { title: 'Fødevarer', value: 'Fødevarer' },
        { title: 'Mode', value: 'Mode' },
        { title: 'Storcentre', value: 'Storcentre' },
        { title: 'Pension & forsikring', value: 'Pension & forsikring' },
        { title: 'Alkohol & læskedrik', value: 'Alkohol & læskedrik' },
        { title: 'Medier', value: 'Medier' },
        { title: 'Personlig Pleje', value: 'Personlig Pleje' },
        { title: 'Bank & realkredit', value: 'Bank & realkredit' },
        { title: 'Hi-Fi & elektronik', value: 'Hi-Fi & elektronik' },
        { title: 'IT & Tele', value: 'IT & Tele' },
        { title: 'Sport & Fritid', value: 'Sport & Fritid' },
        { title: 'Accessories', value: 'Accessories' },
        { title: 'Underholdning', value: 'Underholdning' },
        { title: 'Betting & Casino', value: 'Betting & Casino' },
        { title: 'Optiker', value: 'Optiker' },
        { title: 'Rejser', value: 'Rejser' },
        { title: 'Uddannelse & kurser', value: 'Uddannelse & kurser' },
        { title: 'Dagligvarekæder', value: 'Dagligvarekæder' },
        { title: 'Medicinal', value: 'Medicinal' },
        { title: 'Forlystelser', value: 'Forlystelser' },
        { title: 'Off. Institutioner', value: 'Off. Institutioner' },
        { title: 'Convenience', value: 'Convenience' },
        { title: 'Forlag', value: 'Forlag' },
        { title: 'Transport', value: 'Transport' },
        { title: 'Bolig-Hus-Have', value: 'Bolig-Hus-Have' },
        { title: 'Ejendomsmægler', value: 'Ejendomsmægler' },
        { title: 'Biler', value: 'Biler' },
      ],
      info: { head: 'Branche', body: 'Angiv hvilken branche der ønskes som benchmark' },
    },
    product: {
      errorText: 'Angiv produkt egenskab',
      title: 'Angiv produktegenskab',
      prefix: '',
      postfix: 'Produkt',
      data: [
        { title: 'Eksisterende', value: 'Existing' },
        { title: 'Nyt', value: 'New' },
      ],
      info: { head: 'Produkt', body: 'Er det et nyt eller eksisterende produkt, der annonceres for i kampagnen?' },
    },
    week: {
      errorText: 'Angiv kampagne start',
      type: 'slider',
      label: 'Angiv kampagne start',
      prefix: 'Uge',
      postfix: '',
      initialValue: 25,
      min: 1,
      max: 52,
      snap: 1,
      info: { head: 'Uge', body: 'Vælg hvilken uge kampagnen starter.' },
    },
    posters: {
      errorText: 'Angiv antal plakater',
      type: 'slider',
      label: 'Angiv antal plakater',
      initialValue: 1000,
      prefix: '',
      postfix: 'Plakater',
      min: 100,
      max: 2000,
      snap: 100,
      info: {
        head: 'Plakater',
        body: 'Angiv det samlede antal plakater, som du ønsker reklameerindringen estimeret på baggrund af.',
      },
    },
  },
  spinnerPage: {
    labels: [
      'Aktiverer Cloud Vision API...',
      'Modtager ML estimat... ',
      'Behandler data...',
      'Estimerer kampagneeffekt...',
    ],
  },
  resultPage: {
    header: 'Estimeret reklameerindring',
    body: 'Tallet illustrerer hvor stor en procentdel der estimeres vil huske at have set reklamen i bymiljøet',
    resultDescription:
      'Resultater er estimerede reklameerindringer baseret på nedenstående variable samt reklamernes kreative elementer',
    shareBtn: 'Del',
    sharePopupText: 'Del følgende link',
    sharePopupBtn: 'Kopiere',
    regenerateBtn: 'Genberegn',
    uploadNewBtn: 'Tilføj ny plakat',
    yourBoble: 'Estimeres at kunne huske din reklame på baggrund af dette kreativ.',
    industryBoble: 'Gennemsnitlig\nreklameerindring',
    info: {
      head: 'Måleenhed',
      body:
        '"Husker at have set reklamen på outdoor" er en delmængde af forbrugere, der husker kampagnen. Eks.\n \n' +
        'Antag at 100 forbrugere har set kampagnen. Iblandt disse husker 30 af forbrugerne reklamen fra outdoor plakater. Dette resulterer i 30%, der repræsenterer 30 ud af 100 forbrugere, der husker reklamen fra outdoor plakater',
    },
  },
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11/06/2021.
 */
import React, { useEffect } from 'react'

import usePortal from 'react-cool-portal'

const PopOverModal = ({ showModal, setVisible = null, children }) => {
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false, // The default visibility of portal, default is true
    onShow: (e) => {},
    onHide: (e) => {
      setVisible(false)
    },
  })

  useEffect(() => {
    if (showModal) {
      show()
    } else {
      hide()
    }
  }, [showModal, hide, show])

  return (
    isShow && (
      <>
        <Portal>
          <div
            className="position-absolute"
            style={{
              top: showModal.y || 0,
              left: showModal.x || 0,
              zIndex: 2000,
            }}
          >
            {children}
          </div>
        </Portal>
      </>
    )
  )
}

export default PopOverModal

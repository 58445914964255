/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/12/2021.
 */
import React, { forwardRef, useRef } from 'react'
import { gsap } from 'gsap'

const Btn = forwardRef(({ background, label, width = 'auto', style = {}, onClick }, ref) => {
  const textRefOne = useRef()
  const textRefTwo = useRef()
  const bgRef = useRef()

  const mouseOver = () => {
    gsap.killTweensOf(bgRef.current)
    gsap.killTweensOf(textRefOne.current)
    gsap.killTweensOf(textRefTwo.current)
    gsap.to(bgRef.current, 0.5, { scaleY: 1.08, scaleX: 1.05, ease: 'Expo.easeOut' })
    gsap.to(textRefOne.current, 0.4, { y: -10, alpha: 0, ease: 'Expo.easeOut' })
    gsap.to(textRefTwo.current, { duration: 0.4, alpha: 1, y: -48, ease: 'Expo.easeOut' })
  }

  const mouseOut = () => {
    gsap.killTweensOf(bgRef.current)
    gsap.killTweensOf(textRefOne.current)
    gsap.killTweensOf(textRefTwo.current)
    gsap.to(bgRef.current, 0.4, { scaleX: 1, scaleY: 1, ease: 'Expo.easeOut' })
    gsap.to(textRefOne.current, 0.5, { y: 0, alpha: 1, ease: 'Expo.easeOut' })
    gsap.to(textRefTwo.current, { duration: 0.4, alpha: 0, y: -35, ease: 'Expo.easeOut' })
  }

  const clickker = (e) => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <div
      ref={ref}
      onClick={clickker}
      className="position-relative pointer noselect mt-md-0 mt-4"
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <div
        ref={bgRef}
        className="btn px-5 py-3 mr-3 rounded-pill noselect"
        style={{
          border: 'none',
          background: background,
          width: width,
          height: '3rem',
          ...style,
        }}
      ></div>
      <div
        className="position-absolute noselect overflow-hidden"
        style={{ top: 0, left: 0, width: width, height: '3rem' }}
      >
        <p
          ref={textRefOne}
          className="mb-0 d-flex justify-content-center align-items-center h-100"
          style={{ color: '#FFFFFF' }}
        >
          {label}
        </p>
        <p
          ref={textRefTwo}
          className="mb-0 d-flex justify-content-center align-items-center h-100"
          style={{ color: '#FFFFFF' }}
        >
          {label}
        </p>
      </div>
    </div>
  )
})
export default Btn

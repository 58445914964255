/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/09/2021.
 */
import React from 'react'

import AfaIconUrl from '../assets/svg/afaLogo.svg'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'
import { getLanguage } from '../constants'

const Header = (props) => {
  const { lang } = useParams()
  const LANGUAGE_TEXT = getLanguage(lang)
  const loc = useLocation()

  const daPath = loc.pathname.indexOf('/da/') === -1 ? loc.pathname.split('/en').join('/da') : loc.pathname
  const enPath = loc.pathname.indexOf('/en/') === -1 ? loc.pathname.split('/da').join('/en') : loc.pathname

  return (
    <div
      className="container-fluid bg-white position-relative"
      style={{ zIndex: 100, boxShadow: '0px -5px 10px 7px rgba(0, 0, 0, 0.05)' }}
    >
      <div className="row pb-1" style={{}}>
        <div className="col-6 ">
          <Link to={'/' + lang} className="d-flex py-2 pointer text-decoration-none">
            <img style={{ marginTop: 3, height: '2.3rem' }} src={AfaIconUrl} alt="" />
          </Link>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center ">
          <div className="d-flex mr-2">
            <a
              href={'mailto:' + LANGUAGE_TEXT.mailto}
              className="text-decoration-none font-weight-bold p-small mb-0 mt-1 text-dark mt-2 mr-3"
            >
              {LANGUAGE_TEXT.contact}
            </a>

            <NavLink to={daPath} style={{ color: 'black' }} activeClassName="font-weight-bold text-primary ">
              <p className="mt-2 ">DK</p>
            </NavLink>

            <p className="mt-2 px-1">/</p>

            <NavLink to={enPath} style={{ color: 'black' }} activeClassName="font-weight-bold text-primary">
              <p className="mt-2">EN</p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/11/2021.
 */
import React from 'react'
import useMeasure from 'react-use-measure'

const StanderComponent = ({ predictionResult, factor = 30 }) => {
  const imageUrl = predictionResult.inputs.imageUrl
  const [ref, bounds] = useMeasure()

  const calHeightScale = Math.round((window.innerHeight / 100) * 55)
  const heightScale = Math.max(calHeightScale, 400)

  return (
    <>
      <div className="position-relative " style={{ height: heightScale }}>
        <div
          className="position-absolute back-image-cover"
          style={{
            boxShadow: '0px 1px 42px 20px rgba(236, 76, 76, 0.14)',
            backgroundImage: `url(${imageUrl})`,
            left: Math.round((bounds.width / 100) * 55.5),
            top: bounds.top - 70,
            width: bounds.width * 0.91,
            height: bounds.height - 25,
          }}
        ></div>

        <svg width="100%" height="100%" viewBox="0 0 560 573" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="279.914" cy="534.946" rx="279.914" ry="38.0536" fill="url(#paint0_radial_325_2)" />
          <rect x="141.969" y="436.426" width="278.816" height="64.0561" fill="black" />
          <rect ref={ref} x="141.969" y="13.3743" width="278.816" height="420.236" fill="black" />
          <rect x="141.97" width="278.816" height="13.3744" fill="#9FA6AE" />
          <rect x="167.581" width="227.59" height="13.3744" fill="#61625D" />
          <rect x="144.896" y="411.085" width="272.962" height="34.4918" fill="black" />
          <rect width="278.816" height="2.11174" transform="matrix(1 0 0 -1 141.969 475.142)" fill="#3E3E3E" />
          <rect width="278.816" height="2.11174" transform="matrix(1 0 0 -1 141.969 462.471)" fill="#3E3E3E" />
          <rect width="278.816" height="2.11174" transform="matrix(1 0 0 -1 141.969 449.801)" fill="#3E3E3E" />
          <rect x="201.245" y="500.482" width="160.996" height="35.1957" fill="#181818" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.997 3.15648H259.24V4.49858H255.532V6.25613H258.778V7.58339H255.532V10.7882H253.997V3.15648Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M260.575 3.14565H262.321L264.939 10.7882H263.265L262.787 9.21681H260.063L259.552 10.7882H257.936L260.575 3.14565ZM260.48 7.90033H262.355L261.432 4.89241L260.48 7.90033Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M249.639 3.14565H251.385L254.005 10.7882H252.329L251.853 9.21681H249.127L248.616 10.7882H247L249.639 3.14565ZM249.538 7.90033H251.42L250.498 4.89241L249.538 7.90033Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M269.497 7.88954V8.06624C269.507 8.65703 269.567 9.07113 269.682 9.31527C269.797 9.55941 270.033 9.67272 270.405 9.67272C270.777 9.67272 271.007 9.53783 271.127 9.27885C271.213 9.0262 271.249 8.75836 271.233 8.49113V3.14565H272.778V8.4655C272.778 9.11295 272.673 9.62686 272.452 10.0099C272.086 10.6529 271.422 10.9744 270.46 10.9744C269.502 10.9744 268.86 10.7154 268.528 10.1974C268.197 9.67946 268.042 8.96862 268.038 8.06624V7.88954H269.497Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M274.113 3.94419C274.716 3.31563 275.478 3 276.411 3C277.656 3 278.565 3.42174 279.14 4.26522C279.437 4.68129 279.615 5.17544 279.652 5.69095H278.107C278.035 5.38863 277.902 5.10573 277.715 4.86141C277.434 4.52959 277.018 4.36368 276.466 4.36368C276.205 4.35537 275.946 4.41574 275.714 4.53913C275.481 4.66252 275.283 4.84489 275.138 5.06913C274.815 5.53583 274.655 6.19406 274.651 7.05463C274.647 7.91519 274.82 8.55319 275.162 8.98348C275.317 9.18674 275.516 9.35011 275.742 9.4608C275.969 9.57149 276.217 9.62649 276.467 9.62148C277.009 9.62148 277.42 9.43939 277.706 9.07115C277.893 8.80613 278.026 8.50466 278.098 8.18496H279.628C279.523 8.96487 279.16 9.68304 278.6 10.2177C278.053 10.7356 277.35 10.9946 276.487 11C275.423 11 274.591 10.648 273.979 9.94251C273.366 9.23706 273.071 8.26859 273.065 7.029C273.065 5.69634 273.413 4.66807 274.109 3.94419H274.113Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M295.189 7.16252C295.166 6.9578 295.095 6.76192 294.983 6.59195C294.822 6.364 294.577 6.24935 294.235 6.24935C293.753 6.24935 293.428 6.49349 293.247 6.99121C293.146 7.33043 293.098 7.6844 293.107 8.03927C293.095 8.37841 293.142 8.71703 293.247 9.03876C293.309 9.25011 293.438 9.43404 293.612 9.5617C293.787 9.68937 293.998 9.75356 294.211 9.74421C294.541 9.74421 294.776 9.65113 294.923 9.46499C295.075 9.25369 295.165 9.00195 295.184 8.73932H296.644C296.609 9.20236 296.445 9.64497 296.172 10.014C295.726 10.6574 295.063 10.9784 294.18 10.9838C293.296 10.9892 292.656 10.714 292.239 10.1745C291.823 9.63495 291.622 8.9349 291.617 8.07434C291.617 7.10586 291.842 6.35321 292.304 5.80962C292.766 5.26604 293.393 5.00032 294.201 5.00032C294.882 5.00032 295.439 5.15543 295.88 5.47646C296.322 5.79748 296.574 6.35321 296.664 7.16252H295.189Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M303.774 5.13928V8.54511C303.761 8.79282 303.799 9.04056 303.885 9.27214C304.015 9.55675 304.276 9.69703 304.652 9.70242C305.139 9.70242 305.47 9.5001 305.656 9.09005C305.757 8.80982 305.805 8.51218 305.797 8.2133V5.13928H307.247V10.7909H305.857V9.99512C305.846 10.01 305.816 10.0626 305.756 10.1502C305.705 10.236 305.64 10.3129 305.566 10.3782C305.378 10.5644 305.156 10.7094 304.913 10.8044C304.676 10.8812 304.429 10.9194 304.181 10.9177C303.373 10.9177 302.831 10.6169 302.546 10.0154C302.39 9.68354 302.315 9.19661 302.309 8.54781V5.13928H303.774Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M307.592 10.7882L309.458 7.92597L307.671 5.14735H309.423L310.336 6.78485L311.229 5.14735H312.93L311.134 7.90034L313 10.7882H311.218L310.276 9.09272L309.327 10.7882H307.592Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284.235 3.31157C284.745 3.48402 285.187 3.82468 285.493 4.28139C285.744 4.6525 285.922 5.07158 286.015 5.51423C286.102 5.93023 286.149 6.35398 286.156 6.77945C286.156 7.79648 285.96 8.65704 285.563 9.36249C285.032 10.3067 284.209 10.7828 283.084 10.7882H279.904V3.14566H283.09C283.478 3.13786 283.864 3.19387 284.235 3.31157ZM281.405 4.47292V9.46365H282.829C283.557 9.46365 284.064 9.09542 284.355 8.34816C284.519 7.88174 284.599 7.38782 284.59 6.8914C284.59 6.11312 284.475 5.51693 284.235 5.10284C283.995 4.68874 283.536 4.48102 282.829 4.47562L281.405 4.47292Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M290.095 5.24989C290.466 5.43401 290.779 5.72303 290.998 6.08347C291.216 6.43727 291.358 6.83508 291.414 7.25022C291.455 7.62184 291.47 7.99594 291.46 8.36977H287.717C287.738 8.93493 287.907 9.33014 288.239 9.56349C288.45 9.70654 288.699 9.78034 288.951 9.77526C289.214 9.78715 289.471 9.69445 289.669 9.51628C289.779 9.40449 289.867 9.2728 289.93 9.12782H291.389C291.354 9.45963 291.19 9.80224 290.883 10.1489C290.417 10.6979 289.765 10.973 288.916 10.973C288.241 10.981 287.586 10.734 287.075 10.2784C286.543 9.81708 286.277 9.06442 286.277 8.01772C286.277 7.0425 286.513 6.29659 286.995 5.76784C287.477 5.2391 288.098 4.98956 288.867 4.98416C289.29 4.9805 289.709 5.07257 290.095 5.25393V5.24989ZM288.094 6.52589C287.9 6.77543 287.781 7.07844 287.752 7.39725H289.971C289.972 7.23327 289.943 7.07057 289.883 6.91849C289.824 6.76641 289.736 6.62795 289.625 6.51106C289.524 6.41064 289.404 6.33189 289.273 6.27934C289.143 6.2268 289.003 6.20149 288.863 6.20487C288.719 6.19581 288.575 6.21991 288.441 6.27544C288.308 6.33096 288.188 6.41651 288.09 6.52589H288.094Z"
            fill="#E4E4E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M299.284 7.35004C299.478 7.33448 299.668 7.28902 299.849 7.21515C299.936 7.18722 300.013 7.13171 300.068 7.05645C300.123 6.98118 300.154 6.88997 300.156 6.79566C300.165 6.70121 300.148 6.60607 300.106 6.52147C300.064 6.43687 300 6.36633 299.921 6.31817C299.707 6.2183 299.473 6.17208 299.238 6.18329C298.902 6.18329 298.667 6.26692 298.521 6.44227C298.41 6.59369 298.341 6.77301 298.32 6.96157H296.94C296.953 6.52426 297.093 6.10107 297.342 5.74761C297.718 5.25213 298.367 5.0044 299.288 5.0044C299.841 4.99185 300.388 5.11822 300.883 5.37263C301.345 5.61542 301.576 6.07808 301.581 6.76194V9.35442C301.581 9.53112 301.581 9.74828 301.592 10.0073C301.587 10.1443 301.616 10.2805 301.676 10.4025C301.731 10.4777 301.804 10.5367 301.888 10.5738V10.7909H300.332C300.294 10.6864 300.264 10.5791 300.241 10.4699C300.226 10.3715 300.216 10.2568 300.202 10.1273C300.001 10.3484 299.772 10.5388 299.519 10.6925C299.2 10.8743 298.84 10.9673 298.475 10.9622C298.027 10.9777 297.59 10.8226 297.246 10.5266C296.926 10.2352 296.766 9.82022 296.765 9.28158C296.765 8.58693 297.02 8.08381 297.542 7.77358C297.824 7.60767 298.24 7.48762 298.796 7.40939L299.284 7.34734V7.35004ZM300.152 8.04065C300.063 8.09542 299.971 8.14365 299.875 8.18497C299.784 8.216 299.66 8.24702 299.5 8.28344L299.177 8.34549C298.952 8.37335 298.733 8.44183 298.531 8.54781C298.441 8.61123 298.366 8.69387 298.309 8.79014C298.253 8.88642 298.218 8.99406 298.205 9.10576C298.193 9.21746 298.204 9.3306 298.238 9.43751C298.271 9.54442 298.327 9.64259 298.401 9.72535C298.544 9.83479 298.718 9.89302 298.897 9.89126C299.201 9.89195 299.498 9.79381 299.745 9.6107C300.006 9.42456 300.136 9.08735 300.152 8.58963V8.04065Z"
            fill="#E4E4E3"
          />
          <defs>
            <radialGradient
              id="paint0_radial_325_2"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(279.914 534.946) rotate(90) scale(38.0536 279.914)"
            >
              <stop offset="0.3125" stopColor="#262626" />
              <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </>
  )
}
export default StanderComponent

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/09/2021.
 */
import React, { useEffect, useRef, useState } from 'react'
import Spinner from '../components/spinner/Spinner'
import LoadingText from '../components/spinner/LoadingText'
import { Redirect, useParams } from 'react-router-dom'
import { projectStore, SINGLE_MODE, VS_MODE } from '../store/ProjectStore'
import { getLanguage, REGENERATE } from '../constants'

const SpinnerPage = (props) => {
  const { lang } = useParams()
  const LANGUAGE_TEXT = getLanguage(lang)
  const [hasErrors, setErrors] = useState(false)

  const predictionResult = projectStore((state) => state.predictionResult)
  const predictionResultTwo = projectStore((state) => state.predictionResultTwo)

  const isLoading = useRef(false)
  const processing = projectStore((state) => state.processing)
  const processingVsMode = projectStore((state) => state.processingVsMode)
  const mode = projectStore((state) => state.mode)
  const predict = projectStore((state) => state.predict)
  const predictImageTwo = projectStore((state) => state.predictImageTwo)
  const regenerate = projectStore((state) => state.regenerate)
  const regenerateTwo = projectStore((state) => state.regenerateTwo)
  const validate = projectStore((state) => state.validate)

  useEffect(() => {
    if (processing === false && isLoading.current === false) {
      //setIsloading(true)
      isLoading.current = true

      const id = setTimeout(() => {
        const errors = validate()

        if (errors.length === 0) {
          if (mode === SINGLE_MODE) {
            predict()
          } else {
            predict()
            predictImageTwo()
            /*setTimeout(() => {
						  predictImageTwo()
						}, 2000)*/
          }
        } else {
          console.log(' SpinnerPage > werrors = ', errors)
          setErrors(errors)
        }
      }, 600)
      return () => {
        clearTimeout(id)
      }
    }
    if (processing === REGENERATE && isLoading.current === false && predictionResult && predictionResultTwo) {
      isLoading.current = true

      regenerateTwo(predictionResultTwo.inputs.imageUrl)
      regenerate(predictionResult.inputs.imageUrl)

      /*const id = setTimeout(() => {
			  regenerateTwo(predictionResultTwo.inputs.imageUrl)
			  regenerate(predictionResult.inputs.imageUrl)
			}, 600)
			return () => {
			  clearTimeout(id)
			}*/
    }
  }, [
    predict,
    processing,
    regenerate,
    validate,
    predictionResult,
    predictImageTwo,
    mode,
    predictionResultTwo,
    regenerateTwo,
  ])

  if (hasErrors) {
    return <Redirect to={'/' + lang}></Redirect>
  }

  if (mode === SINGLE_MODE && processing === 'DONE') {
    return <Redirect to={`/${lang}/result/${predictionResult.id}`}></Redirect>
  }

  if (mode === VS_MODE && processing === 'DONE' && processingVsMode === 'DONE') {
    return <Redirect to={`/${lang}/result/${predictionResult.id}/vs/${predictionResultTwo.id}`}></Redirect>
  }

  return (
    <div className="container-fluid ">
      <div className="row" style={{ height: '70vh' }}>
        <div className="col-12 h-100 d-flex justify-content-center align-items-center">
          <Spinner size={9} style={{ marginLeft: 30 }}></Spinner>
          <div>
            <LoadingText LANGUAGE_TEXT={LANGUAGE_TEXT}></LoadingText>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SpinnerPage

import React, { useEffect, useRef } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { animated, useSpringRef, useTransition } from '@react-spring/web'

export const AnimatedRoute = ({ children, className }) => {
  const location = useLocation()
  const state = location.state
  const oldIndex = useRef(0)

  const direction = state && state.index < oldIndex.current ? 200 : -200 // 100 //index < oldIndex.current ? 200 : -200

  oldIndex.current = state?.index ? state.index : 0

  const transRef = useSpringRef()
  const transitions = useTransition(location, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: `translate3d(${-direction}px,0px,0px)` },
    enter: { opacity: 1, transform: 'translate3d(0px,0px,0px)' },
    leave: { opacity: 0, transform: `translate3d(${direction}px,0px,0px)` },
  })

  useEffect(() => {
    if (transRef) {
      transRef.start()
    }
  }, [location.pathname, transRef])

  return (
    <div className={{ className }}>
      {transitions((style, i) => {
        return (
          <animated.div className="position-absolute w-100 " style={{ ...style }}>
            <Switch location={location}>{children}</Switch>
          </animated.div>
        )
      })}
    </div>
  )
}

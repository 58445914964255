/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/09/2021.
 */
import React, { useRef, useState } from 'react'
import arrowSvg from '../assets/svg/caret-down-fill.svg'
import PopOverModal from './PopOverModal'
import useMeasure from 'react-use-measure'

const SelectBox = ({
  initialValue,
  preSelected = null,
  items,
  setValue,
  prefix = '',
  postfix = '',
  error,
  position = 'bottom',
}) => {
  const [visible, setVisible] = useState(false)

  const [selected, setSelected] = useState(preSelected === null ? null : preSelected)

  const [ref, bounds] = useMeasure()
  const myRef = useRef()

  const open = (e) => {
    if (!visible) {
      const rect = myRef.current.getBoundingClientRect()
      setVisible(rect)
    } else {
      setVisible(false)
    }
  }

  const onSelect = (index) => {
    const item = items[index]

    setSelected(item)
    if (setValue) {
      setValue(item)
    }

    setVisible(false)
  }

  const top = position === 'bottom' ? 30 : -(bounds.height - 40) + window.scrollY

  const selectedTitle = selected && selected.title ? selected.title : selected

  return (
    <div className="w-100 position-relative d-flex justify-content-center flex-column">
      <PopOverModal showModal={visible} setVisible={setVisible}>
        <div
          ref={ref}
          className="position-absolute bg-white select-drop-down pt-3  "
          style={{
            top: top,
            left: -50,
            zIndex: 100,
            maxHeight: 300,
            overflowY: 'auto',
          }}
        >
          {items.map((item, index) => {
            return <ListItem key={index} data={item} index={index} prefix={prefix} onSelect={onSelect}></ListItem>
          })}
        </div>
      </PopOverModal>

      {selected !== null && preSelected === null ? <p className="mb-0 text-center p-small">{initialValue}</p> : null}

      <div onClick={open} className="d-flex pointer justify-content-center">
        <p ref={myRef} className="mb-0 font-weight-bold text-center p-small">
          {selected ? prefix + ' ' + selectedTitle + ' ' + postfix : initialValue}
        </p>
        <img className="ml-2 mt-1" style={{ width: 12, height: 12 }} src={arrowSvg} alt="" />
      </div>
      {error && (
        <p className="text-center p-small text-danger mt-2" style={{ fontSize: '.7rem' }}>
          {error.message}
        </p>
      )}
    </div>
  )
}
export default SelectBox

const ListItem = ({ data, index, onSelect, prefix }) => {
  const ref = useRef()
  const clickker = () => {
    onSelect(index)
  }

  const title = data.title ? data.title : data

  return (
    <div onClick={clickker} id={index} ref={ref} className="noselect pointer mb-2 pl-3 pr-4" style={{ width: 200 }}>
      <p className="" style={{ pointerEvents: 'none' }}>
        {prefix} {title}
      </p>
    </div>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/09/2021.
 */
import React, { useEffect, useRef, useState } from 'react'
import { projectStore } from '../store/ProjectStore'

import { DEFAULT_COLOR, getLanguage, PRIMARY_COLOR, REGENERATE, SECONDARY_COLOR } from '../constants'
import { Link, useParams } from 'react-router-dom'
import bubleBackground from '../assets/background.jpg'
import StanderComponent from '../components/StanderComponent'

import gsap from 'gsap'
import Btn from '../components/Btn'
import Spinner from '../components/spinner/Spinner'

import ShareBtn from '../components/ShareBtn'
import useMeasure from 'react-use-measure'
import PopUpInfo from '../components/popup/PopUpInfo'
import RegenerateVsMode from '../components/RegenerateVsMode'

const ResultPageVsMode = () => {
  const { lang, id, vsid } = useParams()

  const LANGUAGE_TEXT = getLanguage(lang)

  const [ref, bounds] = useMeasure()
  const scaleFactor = Math.min(bounds.width - 150, 300)

  const week = projectStore((state) => state.week)
  const product = projectStore((state) => state.product)
  const poster = projectStore((state) => state.poster) || '?'
  const industry = projectStore((state) => state.industry)
  const setProcessing = projectStore((state) => state.setProcessing)

  const predictionResult = projectStore((state) => state.predictionResult)
  const predictionResultTwo = projectStore((state) => state.predictionResultTwo)
  const loadProject = projectStore((state) => state.loadProject)
  const loadProjectTwo = projectStore((state) => state.loadProjectTwo)
  const [readyRegenerate, setReadyRegenerate] = useState(false)

  useEffect(() => {
    let ready = false

    if (predictionResult) {
      if (predictionResult.inputs.weekStart !== week) {
        ready = true
      }
      if (predictionResult.inputs.product !== product) {
        ready = true
      }
      if (predictionResult.inputs.industry !== industry) {
        ready = true
      }
      if (predictionResult.inputs.posters !== poster) {
        ready = true
      }
    }

    if (ready) {
      setReadyRegenerate(ready)
    }
  }, [poster, week, product, industry, predictionResult])

  useEffect(() => {
    if (!predictionResult) {
      loadProject(id)
        .then((result) => {
          console.log('result = ', result)
        })
        .catch((error) => {
          console.log('error = ', error)
          alert('The Project could not be loaded')
        })
    }

    if (!predictionResultTwo) {
      loadProjectTwo(vsid)
        .then((result) => {
          console.log('result = ', result)
        })
        .catch((error) => {
          console.log('error = ', error)
          alert('The Project could not be loaded')
        })
    }
  }, [id, loadProject, predictionResult, predictionResultTwo, loadProjectTwo, vsid])

  const regenerateClick = () => {
    if (readyRegenerate) {
      setProcessing(REGENERATE)
    }
  }
  //
  // Show spinner while loading data
  //
  if (!predictionResult || !predictionResultTwo) {
    return (
      <div className="container-fluid">
        <div className="row" style={{ height: '60vh' }}>
          <div className="col-12 d-flex justify-content-center align-items-center">
            <Spinner size={12}></Spinner>
          </div>
        </div>
      </div>
    )
  }

  const resultOne = predictionResult.end_prediction * 100
  const resultTwo = predictionResultTwo.end_prediction * 100
  const bestResultObj =
    resultOne > resultTwo ? { value: resultOne, color: PRIMARY_COLOR } : { value: resultOne, color: SECONDARY_COLOR }
  const worstResultObj =
    resultOne < resultTwo ? { value: resultTwo, color: PRIMARY_COLOR } : { value: resultTwo, color: SECONDARY_COLOR }

  console.log(' ResultPageVsMode > scaleFactor = ', scaleFactor)

  return (
    <>
      <div
        className="container-fluid position-fixed h-100 back-image-cover "
        style={{
          width: '100vw',
          minHeight: '100vh',
          marginTop: -220,
          backgroundImage: `linear-gradient(180deg, rgba(245,245,245,0) 80%, rgba(245,245,245,1) 100%, rgba(0,212,255,1) 100%), url(${bubleBackground}) `,
          backgroundRepeat: 'no-repeat',
          zIndex: -1,
        }}
      >
        {/*INFO DOT IN CORNER*/}
        <div className="position-absolute" style={{ top: 240, right: 15 }}>
          <div className="d-flex">
            <p className="mr-2">{LANGUAGE_TEXT.resultPage.info.head}</p>
            <PopUpInfo size={20} position="left top" maxWidth={500} text={LANGUAGE_TEXT.resultPage.info}></PopUpInfo>
          </div>
        </div>
      </div>

      {/*THE ACTUAL CONTENT*/}

      <div className="container ">
        <div className="row h-100 position-relative">
          <div className="position-absolute w-100 h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div
                className="bg-white"
                style={{ width: 600 - scaleFactor, boxShadow: '0px 4px 20px -1px rgba(0, 0, 0, 0.22)' }}
              >
                <p className="text-center p-6 font-weight-bold">{LANGUAGE_TEXT.resultPage.resultDescription}</p>
              </div>
            </div>
          </div>
          <div
            className="col-6 mt-4 d-flex justify-content-center align-items-center"
            style={{ minHeight: 200, minWidth: 560 }}
          >
            <div ref={ref} className="position-relative" style={{ left: 30 }}>
              <StanderComponent predictionResult={predictionResult} factor={180}></StanderComponent>
              <Blob
                LANGUAGE_TEXT={LANGUAGE_TEXT}
                displayValue={bestResultObj.value}
                label={'Your campaign'}
                style={{ zIndex: -1, top: -50, left: 0 - (scaleFactor - 150) * 0.5 }}
                size={scaleFactor - 70}
                color={bestResultObj.color}
                type={'YOUR'}
              ></Blob>
            </div>
          </div>

          <div
            className="col-6 mt-4 d-flex justify-content-center align-items-center"
            style={{ minHeight: 200, minWidth: 560 }}
          >
            <div ref={ref} className="position-relative" style={{ left: -30 }}>
              <StanderComponent predictionResult={predictionResultTwo} factor={180}></StanderComponent>

              <Blob
                LANGUAGE_TEXT={LANGUAGE_TEXT}
                displayValue={worstResultObj.value}
                label={'Your campaign'}
                style={{ zIndex: -1, top: -50, left: bounds.width - (scaleFactor - 120) }}
                size={scaleFactor - 70}
                color={worstResultObj.color}
                type={'COMPARE'}
              ></Blob>
            </div>
          </div>
        </div>

        <div className="row" style={{}}>
          <div className="col-12 d-flex justify-content-center">
            <div className="mb-5" style={{ marginTop: -40 }}>
              <h1 className="mb-0 display-4 font-weight-bold text-center">
                {Math.round(predictionResult.industry_average * 100)}%
              </h1>
              <h5 className="mb-3 text-right font-weight-bold text-center" style={{ whiteSpace: 'pre-line' }}>
                {LANGUAGE_TEXT.resultPage.industryBoble}
              </h5>
            </div>
          </div>

          <div className="col-6 pr-5">
            {/*<h1 className="mb-0 display-4 font-weight-bold ">{predictionResult.industry_average * 100}%</h1>
            <h5 className="mb-3 font-weight-bold">{LANGUAGE_TEXT.resultPage.industryBoble}</h5>*/}
            <Line></Line>
            <RegenerateVsMode
              LANGUAGE_TEXT={LANGUAGE_TEXT}
              predictionResult={predictionResult}
              updateIndex={0}
            ></RegenerateVsMode>
          </div>
          <div className="col-6 pl-5 ">
            {/*<h1 className="mb-0 display-4 font-weight-bold text-right">{predictionResult.industry_average * 100}%</h1>
            <h5 className="mb-3 text-right font-weight-bold">{LANGUAGE_TEXT.resultPage.industryBoble}</h5>*/}
            <Line></Line>
            <RegenerateVsMode
              LANGUAGE_TEXT={LANGUAGE_TEXT}
              predictionResult={predictionResultTwo}
              updateIndex={1}
            ></RegenerateVsMode>
          </div>
        </div>

        {/*<Regenerate LANGUAGE_TEXT={LANGUAGE_TEXT} predictionResult={predictionResult}></Regenerate>*/}

        <div className="row mb-4 mt-4">
          <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-md-center">
            <ShareBtn
              LANGUAGE_TEXT={LANGUAGE_TEXT}
              lang={lang}
              predictionResult={predictionResult}
              predictionResultTwo={predictionResultTwo}
            ></ShareBtn>

            <Link className={readyRegenerate ? ' ' : 'disabled-link '} to={`/${lang}/calculating`}>
              <Btn
                onClick={regenerateClick}
                background={readyRegenerate ? '#EC4C4C' : DEFAULT_COLOR}
                label={LANGUAGE_TEXT.resultPage.regenerateBtn}
                width={180}
              ></Btn>
            </Link>
            <Link
              to={{
                pathname: `/${lang}`,
                state: { week: week, industry: industry },
              }}
            >
              <Btn background={PRIMARY_COLOR} label={LANGUAGE_TEXT.resultPage.uploadNewBtn} width={180}></Btn>
            </Link>
          </div>
        </div>

        <div className="row" style={{}}>
          <div className="col-8 mx-auto mb-6 mt-0">
            <h1 className="display-4 text-center font-weight-bold mt-3">{LANGUAGE_TEXT.resultPage.header}</h1>
            <p className="p-small text-center">{LANGUAGE_TEXT.resultPage.body}</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default ResultPageVsMode

const Blob = ({
  style,
  blobStyle = {},
  props,
  color,
  size = 350,
  speed = '20s',
  displayValue,
  type,
  LANGUAGE_TEXT,
}) => {
  const fontSize = size / 3.8 //'3.5vw' // type === 'YOUR' ? '3.5vw' : '3.5vw' // startSize - 10
  const leftPad = 0 // type === 'YOUR' ? -50 : 100
  const topPad = 0 //type === 'YOUR' ? 0 : 150
  const isLeft = type === 'YOUR'

  const blobDelay = isLeft ? 0.8 : 0.8

  const bobleRef = useRef()
  const numContainer = useRef()
  const container = useRef()

  useEffect(() => {
    if (!bobleRef.current) {
      return
    }

    gsap.fromTo(bobleRef.current, { scale: 0 }, { alpha: 1, scale: 1, delay: blobDelay, ease: 'power3.out' })
    gsap.fromTo(
      numContainer.current,
      { y: 250, ease: 'power3.out' },
      { alpha: 1, y: 0, duration: 1, delay: blobDelay, ease: 'power3.out' }
    )

    gsap.fromTo(
      container.current,
      { y: 250, ease: 'power3.out' },
      {
        duration: 1,
        y: isLeft ? 200 : 200,

        delay: blobDelay,
        ease: 'power3.out',
        onComplete: () => {
          gsap.to(bobleRef.current, {
            x: 'random(0, 0)',
            y: 'random(-5, 5)',
            scale: 'random(.99, 1.05)',
            duration: 2,
            yoyo: true,
            repeat: 50,
            ease: 'sine.inOut',
          })
        },
      }
    )
  }, [displayValue, blobDelay, isLeft])

  const leftpos = isLeft ? -130 : 70
  const clampLeftpos = size < 230 ? -120 : -50
  const clampRight = size < 200 ? 40 : 70

  const clampToptpos = size < 230 ? 100 : 65

  return (
    <div ref={container} className="position-absolute" style={{ height: size, width: size, ...style }}>
      <div
        ref={bobleRef}
        className="position-absolute rounded-circle w-100 h-100"
        style={{ background: color, opacity: 0 }}
      ></div>
      <div
        ref={numContainer}
        className="position-absolute d-flex flex-column align-items-center h-100"
        style={{
          top: isLeft ? 78 : 78,
          left: isLeft ? clampLeftpos : clampRight,
          paddingTop: topPad,
          opacity: 0,
        }}
      >
        <div className="d-flex align-items-center" style={{ width: 250 }}>
          {isLeft && <div className="mr-3 bg-dark" style={{ height: 4, width: 200 }}></div>}

          <AnimatedNumber delay={blobDelay + 0.3} displayValue={displayValue} fontSize={fontSize}></AnimatedNumber>
          {!isLeft && <div className="ml-3 bg-dark" style={{ height: 3, width: 100 }}></div>}
        </div>

        {/*<div className={` mt-n2 ${isLeft ? ' text-right ' : 'text-left'}`} style={{ width: 250 }}>
          <p
            className="mb-0 "
            style={{
              fontSize: '.8rem',
              lineHeight: 1.2,
              color: 'black',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
                        {LANGUAGE_TEXT.resultPage.yourBoble}
          </p>
        </div>*/}
      </div>
    </div>
  )
}

const AnimatedNumber = ({ delay, displayValue, fontSize }) => {
  const ref = useRef()
  const foo = useRef({ bar: 0 })

  useEffect(() => {
    gsap.to(foo.current, {
      bar: Math.round(displayValue),
      duration: 1,
      //roundProps: 'value',
      ease: 'power2.out',
      delay: delay,
      onUpdate: function () {
        if (ref.current && foo.current.bar) {
          ref.current.innerText = Math.round(foo.current.bar) + '%'
        }
      },
    })
  })

  return (
    <h4 ref={ref} className="font-weight-bold mb-0 " style={{ fontSize: fontSize }}>
      <span>%</span>
    </h4>
  )
}

const Line = () => {
  return <div className="w-100" style={{ background: 'black', height: 2 }}></div>
}

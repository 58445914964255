/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05/11/2021.
 */
import { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

export const BlobImage = function ({ file, index }) {
  const [blobImg, setBlob] = useState('')
  //const [, setHoverProps] = useSpring(() => ({ opacity: 0, config: { frition: 20, tension: 250 } }))
  //const [aniProps, api] = useSpring({ opacity: 0 })
  const [aniProps, api] = useSpring(() => ({ from: { opacity: 0 } }))

  const deleteClick = () => {
    //deleteImage(index)
  }

  /*const bind = useGesture({
	  onHover: ({ hovering }) => {
		 setHoverProps({
			opacity: hovering ? 1 : 0,
			config: { frition: 200, tension: 500 },
		 })
	  },
	})*/

  useEffect(() => {
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const blob = 'url(' + reader.result + ')'
      setBlob(blob)
      api.start({ opacity: 1, from: { opacity: 0 } })
      reader.onabort = null
      reader.onerror = null
      reader.onload = null
    }

    reader.readAsDataURL(file)
  }, [file, api])

  return (
    <animated.div className="p-2 w-100 h-100" style={aniProps}>
      <div
        onClick={deleteClick}
        className="back-image-cover h-100 w-100"
        //style={{ backgroundImage: blobImg, height: 50, width: 70, boxShadow: '1px 1px 6px 2px rgba(0,0,0,0.08)' }}
        style={{ backgroundImage: blobImg, boxShadow: '1px 1px 6px 2px rgba(0,0,0,0.08)' }}
      >
        {/*<animated.div
          {...bind()}
          className="pointer position-absolute w-100 h-100"
          style={{ paddingLeft: 28, paddingTop: 10, top: 0, left: 0, background: 'rgba(0,0,0,.4)', ...hoverProps }}
        >
          <Icon className="" style={{ color: 'white' }} type="delete" theme="filled" />
          <Delete style={{ marginLeft: -5, height: 25, fill: 'white' }}></Delete>
        </animated.div>*/}
      </div>
    </animated.div>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/12/2021.
 */
import React, { useState } from 'react'
import Btn from './Btn'
import PopOverModal from './PopOverModal'
import useMeasure from 'react-use-measure'
import { BASE_URL, PRIMARY_COLOR } from '../constants'
import copy from 'copy-to-clipboard'

const ShareBtn = ({ predictionResult, predictionResultTwo, lang, LANGUAGE_TEXT }) => {
  const [visible, setVisible] = useState(false)
  const [ref, bounds] = useMeasure()

  const projectLink = predictionResultTwo
    ? BASE_URL + '/' + lang + '/result/' + predictionResult.id + '/vs/' + predictionResultTwo.id
    : BASE_URL + '/' + lang + '/result/' + predictionResult.id

  const copyText = () => {
    //document.execCommand(BASE_URL + '/result/' + predictionResult.id)
    //copy(BASE_URL + '/result/' + predictionResult.id)
    copy(projectLink)
    //document.execCommand(BASE_URL + '/result/' + predictionResult.id)

    /*navigator.permissions
		  .query({ name: 'clipboard-write' })
		  .then((result) => {
			 if (result.state.toString() === 'granted' || result.state.toString() === 'prompt') {
				navigator.clipboard.writeText(projectLink)
			 } else {
				alert(result.toString())
			 }
		  })
		  .catch((error) => {
			 alert(error.toString())
		  })
  */
    setVisible(false)
  }

  const shareClick = (e) => {
    if (!visible) {
      setVisible({ x: bounds.x - 20, y: bounds.y - 200 })
    } else {
      setVisible(false)
    }
  }
  return (
    <div className="position-relative">
      <PopOverModal showModal={visible} setVisible={setVisible}>
        <div
          className=" bg-white select-drop-down "
          style={{
            zIndex: 100,
            maxHeight: 300,
            maxWidth: 370,
            overflowY: 'auto',
          }}
        >
          <div className="w-100 p-4">
            <p className="mb-2 text-center">{LANGUAGE_TEXT.resultPage.sharePopupText}</p>
            <div className="py-2 px-3" style={{ background: '#f3f3f3', border: '1px solid rgba(0,0,0,.2)' }}>
              <p className="p-small text-truncate">{projectLink}</p>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Btn
                onClick={copyText}
                background={PRIMARY_COLOR}
                label={LANGUAGE_TEXT.resultPage.sharePopupBtn}
                width={200}
              ></Btn>
            </div>
          </div>
        </div>
      </PopOverModal>

      <Btn
        ref={ref}
        onClick={shareClick}
        background="#000000"
        label={LANGUAGE_TEXT.resultPage.shareBtn}
        width={180}
      ></Btn>
    </div>
  )
}
export default ShareBtn

export const EN_TEXT = {
  contact: 'Contact',
  mailto: 'info@business-science.dk',
  frontPage: {
    headline: 'Estimate ad recall for your OOH creatives',
    body: 'Below you can estimate the effect your OOH creatives have on ad recall. This is being done on the basis of 300 individual ad recall studies, the creatives used in each campaign, machine learning and computer vision. \nYou can test one creative alone or benchmark two against each other.',
    startBtnLabel: 'Estimate ad recall',
  },
  typeSwitch: {
    left: 'Test one creative',
    right: 'Test two creatives',
  },
  dropdowns: {
    image: {
      errorText: 'Please add an image',
      title: 'Upload image',
      type: 'File format/ –dimensions: jpg, png, etc/9:16',
      info: {
        head: 'Image',
        body: 'Upload the poster intended for the OOH campaign. Only one poster per prediction',
      },
    },
    industry: {
      errorText: 'Please choose an industry',
      title: 'Select Industry',
      prefix: '',
      data: [
        { title: 'Food', value: 'Fødevarer' },
        { title: 'Fashion', value: 'Mode' },
        { title: 'Shopping Malls', value: 'Storcentre' },
        { title: 'Pension & Insurance', value: 'Pension & forsikring' },
        { title: 'Beverage', value: 'Alkohol & læskedrik' },
        { title: 'Media', value: 'Medier' },
        { title: 'Personal Hygiene', value: 'Personlig Pleje' },
        { title: 'Banking', value: 'Bank & realkredit' },
        { title: 'Consumer Electronics', value: 'Hi-Fi & elektronik' },
        { title: 'Telecom', value: 'IT & Tele' },
        { title: 'Sport & Leisure', value: 'Sport & Fritid' },
        { title: 'Accessories', value: 'Accessories' },
        { title: 'Entertainment', value: 'Underholdning' },
        { title: 'Betting & Casino', value: 'Betting & Casino' },
        { title: 'Optical', value: 'Optiker' },
        { title: 'Travel', value: 'Rejser' },
        { title: 'Education', value: 'Uddannelse & kurser' },
        { title: 'Grocery Stores', value: 'Dagligvarekæder' },
        { title: 'Pharmaceutical', value: 'Medicinal' },
        { title: 'Amusement Parks', value: 'Forlystelser' },
        { title: 'Government', value: 'Off. Institutioner' },
        { title: 'Convenience', value: 'Convenience' },
        { title: 'Publishing', value: 'Forlag' },
        { title: 'Transportation', value: 'Transport' },
        { title: 'Household Equipment', value: 'Bolig-Hus-Have' },
        { title: 'Real Estate', value: 'Ejendomsmægler' },
        { title: 'Automotive', value: 'Biler' },
      ],
      info: { head: 'Industry', body: 'Select an industry to benchmark campaign against' },
    },
    product: {
      errorText: 'Please choose a type',
      title: 'Select product type',
      prefix: '',
      postfix: 'product',
      data: [
        { title: 'Existing', value: 'Existing' },
        { title: 'New', value: 'New' },
      ],
      info: { head: 'Product', body: 'Is the advertised product a newly introduced or existing product?' },
    },
    week: {
      errorText: 'Select a start time',
      type: 'slider',
      label: 'Set campaign start',
      prefix: 'Week',
      postfix: '',
      initialValue: 25,
      min: 1,
      max: 52,
      snap: 1,
      info: { head: 'Week', body: 'Select the week commencing the campaign.' },
    },
    posters: {
      errorText: 'Select posters',
      type: 'slider',
      label: 'Select amount of posters ',
      initialValue: 1000,
      prefix: '',
      postfix: 'Posters',
      min: 100,
      max: 2000,
      snap: 100,
      info: {
        head: 'Posters',
        body: 'Select the total amount of posters you want the ad recall to be estimated on the basis of.',
      },
    },
  },
  spinnerPage: {
    labels: [
      'Activating Cloud Vision API...',
      'Retrieving ML Predictions...',
      'Processing...',
      'Predicting Campaign Impact...',
    ],
  },
  resultPage: {
    header: 'Estimated ad recall',
    body: 'The number illustrates the percentage estimated to recall having seen your ad in an out-of-home environment',
    resultDescription:
      'Results are estimations of ad recall based on the selected variables below and the specific creatives',
    shareBtn: 'Share',
    sharePopupText: 'Share this URL',
    sharePopupBtn: 'Copy',
    regenerateBtn: 'Regenerate',
    uploadNewBtn: 'Add new image',
    yourBoble: 'Is estimated to remember having seen your ad on the basis of this creative.',
    industryBoble: 'Average ad recall',
    info: {
      head: 'Metric',
      body: 'Remember seeing the OOH ad’ is a subset of all consumers that remember seeing the campaign. Example – Assuming 100 consumers have seen the campaign. Out of those consumers, 30 consumers remember seeing the OOH ad. This results in 30%, which is the 30 out of 100 consumers that remember the ad from OOH. ',
    },
  },
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 27/09/2021.
 */

import { DA_TEXT } from './text/da'
import { EN_TEXT } from './text/en'

//export const BASE_URL = 'http://localhost:8080' // 'https://afa.createwithpeggy.com' //'http://localhost:8080'
export const BASE_URL = 'https://afa.createwithpeggy.com'
export const WEBSITE_URL = 'https://the-afa-website.com' //'http://localhost:8080'
export const PRIMARY_COLOR = '#38B7FF'
export const SECONDARY_COLOR = '#EC4C4C'
export const DEFAULT_COLOR = '#b9b9b9'

export const REGENERATE = 'REGENERATE'
export const CALCULATING = 'CALCULATING'

export const getLanguage = (lang) => {
  return lang === 'da' ? DA_TEXT : EN_TEXT
}

export const industryItems = [
  { da: 'Fødevarer', en: 'Food', value: 'Fødevarer' },
  { da: 'Mode', en: '--', value: 'Mode' },
  { da: 'Storcentre', en: '--', value: 'Storcentre' },
  { da: 'Pension & forsikring', en: '--', value: 'Pension & forsikring' },
  { da: 'Alkohol & læskedrik', en: '--', value: 'Alkohol & læskedrik' },
  { da: 'Medier', en: '--', value: 'Medier' },
  { da: 'Personlig Pleje', en: '--', value: 'Personlig Pleje' },
  { da: 'Bank & realkredit', en: '--', value: 'Bank & realkredit' },
  { da: 'Hi-Fi & elektronik', en: '--', value: 'Hi-Fi & elektronik' },
  { da: 'IT & Tele', en: '--', value: 'IT & Tele' },
  { da: 'Sport & Fritid', en: '--', value: 'Sport & Fritid' },
  { da: 'Accessories', en: '--', value: 'Accessories' },
  { da: 'Underholdning', en: '--', value: 'Underholdning' },
  { da: 'Betting & Casino', en: '--', value: 'Betting & Casino' },
  { da: 'Optiker', en: '--', value: 'Optiker' },
  { da: 'Rejser', en: '--', value: 'Rejser' },
  { da: 'Uddannelse & kurser', en: '--', value: 'Uddannelse & kurser' },
  { da: 'Dagligvarekæder', en: '--', value: 'Dagligvarekæder' },
  { da: 'Medicinal', en: '--', value: 'Medicinal' },
  { da: 'Forlystelser', en: '--', value: 'Forlystelser' },
  { da: 'Off. Institutioner', en: '--', value: 'Off. Institutioner' },
  { da: 'Convenience', en: '--', value: 'Convenience' },
  { da: 'Forlag', en: '--', value: 'Forlag' },
  { da: 'Transport', en: '--', value: 'Transport' },
  { da: 'Bolig-Hus-Have', en: '--', value: 'Bolig-Hus-Have' },
  { da: 'Ejendomsmægler', en: '--', value: 'Ejendomsmægler' },
  { da: 'Biler', en: '--', value: 'Biler' },
]

export const weekItems = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
]

export const postersItems = [
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '1000',
  '1100',
  '1200',
  '1300',
  '1400',
  '1500',
  '1600',
  '1700',
  '1800',
  '1900',
  '2000',
]

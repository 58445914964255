/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/12/2021.
 */
import React from 'react'
import SelectBox from './SelectBox'
import { postersItems, weekItems } from '../constants'
import { projectStore } from '../store/ProjectStore'
import PopUpInfo from './popup/PopUpInfo'

const getSelectedFromValue = (value, data) => {
  //
  // when budiling by accident Exiting was sent instaed Existing for product
  //
  const matchValue = value === 'Exiting' ? 'Existing' : value

  const result = data.filter((item) => {
    if (item.value) {
      return item.value.toString() === matchValue.toString()
    }

    return item.toString() === matchValue.toString()
  })

  return result[0]
}

const Regenerate = ({ predictionResult, LANGUAGE_TEXT }) => {
  const setIndustry = projectStore((state) => state.setIndustry)
  const setProduct = projectStore((state) => state.setProduct)
  const setWeek = projectStore((state) => state.setWeek)
  const setPosters = projectStore((state) => state.setPosters)

  const sortedIndustry = LANGUAGE_TEXT.dropdowns.industry.data.concat([]).sort((a, b) => {
    var nameA = a.title.toLowerCase(),
      nameB = b.title.toLowerCase()
    if (nameA < nameB)
      //sort string ascending
      return -1
    if (nameA > nameB) return 1
    return 0 //default return value (no sorting)
  })

  return (
    <div className="row mx-auto" style={{ maxWidth: 900 }}>
      <div className="col-6 col-md-3 my-4 mt-md-0 d-flex px-0 justify-content-center">
        <div className="d-flex">
          <div className="mr-2" style={{ paddingTop: 2 }}>
            <PopUpInfo size={20} position="top" text={LANGUAGE_TEXT.dropdowns.industry.info}></PopUpInfo>
          </div>
          <SelectBox
            position="top"
            initialValue="Industry"
            preSelected={getSelectedFromValue(predictionResult.inputs.industry, LANGUAGE_TEXT.dropdowns.industry.data)}
            items={sortedIndustry}
            setValue={setIndustry}
            error={null}
          ></SelectBox>
        </div>
      </div>
      <div className="col-6 col-md-3 my-4 mt-md-0 d-flex px-0 justify-content-center">
        <div className="d-flex">
          <div className="mr-1" style={{ paddingTop: 2 }}>
            <PopUpInfo size={20} position="top" text={LANGUAGE_TEXT.dropdowns.product.info}></PopUpInfo>
          </div>
          <SelectBox
            position="top"
            postfix={LANGUAGE_TEXT.dropdowns.product.postfix}
            initialValue=""
            preSelected={getSelectedFromValue(predictionResult.inputs.product, LANGUAGE_TEXT.dropdowns.product.data)}
            items={LANGUAGE_TEXT.dropdowns.product.data}
            setValue={setProduct}
            error={null}
          ></SelectBox>
        </div>
      </div>
      <div className="col-6 col-md-3 my-4 mt-md-0 d-flex px-0 justify-content-center">
        <div className="d-flex">
          <div className="mr-2" style={{ paddingTop: 2 }}>
            <PopUpInfo size={20} position="top" text={LANGUAGE_TEXT.dropdowns.week.info}></PopUpInfo>
          </div>
          <SelectBox
            position="top"
            initialValue="Week"
            prefix={LANGUAGE_TEXT.dropdowns.week.prefix}
            preSelected={getSelectedFromValue(predictionResult.inputs.weekStart, weekItems)}
            items={weekItems}
            setValue={setWeek}
            error={null}
          ></SelectBox>
        </div>
      </div>
      <div className="col-6 col-md-3 my-4 mt-md-0 d-flex px-0 justify-content-center">
        <div className="d-flex">
          <div className="mr-2" style={{ paddingTop: 2 }}>
            <PopUpInfo size={20} position="top" text={LANGUAGE_TEXT.dropdowns.posters.info}></PopUpInfo>
          </div>
          <SelectBox
            position="top"
            initialValue="Posters"
            postfix={LANGUAGE_TEXT.dropdowns.posters.postfix}
            //
            // When we tested in the beginning we did not use posters - therefore posters might not need defined
            //
            preSelected={getSelectedFromValue(predictionResult.inputs.posters || '500', postersItems)}
            items={postersItems}
            setValue={setPosters}
            error={null}
          ></SelectBox>
        </div>
      </div>
    </div>
  )
}
export default Regenerate

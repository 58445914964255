/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05/09/2022.
 */
import React, { useEffect, useRef } from 'react'
import { PRIMARY_COLOR } from '../constants'
import gsap from 'gsap'
import { projectStore, SINGLE_MODE } from '../store/ProjectStore'

const Switch = ({ setValue, labelLeft, labelRight }) => {
  //const [state, setState] = useState(true)

  const toggleMode = projectStore((state) => state.toggleMode)
  const mode = projectStore((state) => state.mode)

  const ref = useRef()
  const leftRef = useRef()
  const rightRef = useRef()

  const toggle = () => {
    /*const newState = !state
		setState(newState)
		if (setValue) {
		  setValue(newState)
		}*/

    toggleMode()
  }

  useEffect(() => {
    const newPos = mode === SINGLE_MODE ? 0 : barWidth - knobWidth

    gsap.to(ref.current, { duration: 0.2, left: newPos, ease: 'back.out' })
    gsap.to(leftRef.current, { duration: 0.2, opacity: mode === SINGLE_MODE ? 1 : 0.5, ease: 'back.out' })
    gsap.to(rightRef.current, { duration: 0.2, opacity: mode === SINGLE_MODE ? 0.5 : 1, ease: 'back.out' })
  })

  const barWidth = 83
  const knobWidth = 34

  return (
    <>
      <div ref={leftRef} className="">
        <p className="text-right mt-3 text-black font-weight-bold">{labelLeft}</p>
        {/*<p className="p-info text-right">Lorem ipsum dolor sit amet, consectetur adipisicing elit dicta</p>*/}
      </div>
      <div onClick={toggle} className="mx-4 d-flex position-relative pointer mt-3">
        <div
          ref={ref}
          className="rounded-pill position-absolute"
          style={{ width: 34, height: 24, background: PRIMARY_COLOR, top: -3 }}
        ></div>
        <div className="rounded-pill" style={{ width: barWidth, height: 17, background: '#D9D9D9' }}></div>
      </div>
      <div ref={rightRef} className=" mt-3">
        <p className="text-black font-weight-bold">{labelRight}</p>
        {/*<p className="p-info">Lorem ipsum dolor sit amet, consectetur adipisicing elit dicta</p>*/}
      </div>
    </>
  )
}
export default Switch

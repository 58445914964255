import { BASE_URL } from '../constants'

export const getData = (endpoint) => {
  //const url = path + query
  const url = BASE_URL + endpoint
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => res)
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

export const post = (endpoint, data) => {
  const p = BASE_URL + endpoint

  return fetch(p, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      // console.log(' API > res = ', res)
      return res
    })
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

// Only used for handling FORM DATA
export const ApiPostForm = async (endpoint, formData) => {
  const p = BASE_URL + endpoint

  return fetch(p, {
    method: 'POST',
    /*headers: {
      Authorization: 'Bearer ' + token,
    },*/
    body: formData,
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      // console.log(' API > res = ', res)
      return res
    })
    .catch((err) => {
      console.log(' API > err = ', err)
      return err
    })
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 03/12/2021.
 */
import React, { useEffect } from 'react'
import { useDrag } from '@use-gesture/react'
import { animated, useSpring } from 'react-spring'
import { PRIMARY_COLOR } from '../constants'

const convertRange = (OldValue, OldMax, OldMin, NewMax, NewMin) => {
  const OldRange = OldMax - OldMin
  const NewRange = NewMax - NewMin
  const NewValue = ((OldValue - OldMin) * NewRange) / OldRange + NewMin
  return NewValue
}

const Slider = ({
  initialValue = 0,
  min = 0,
  max = 200,
  label = '',
  prefix = '',
  postfix = '',
  snapInterval = 1,
  setValue,
  error,
}) => {
  const [{ x }, api] = useSpring(() => ({ x: 0 }))

  const spring = useSpring({ from: { val: 0 }, to: { val: initialValue } })

  const sliderWidth = 200

  useEffect(() => {
    api.start({ x: sliderWidth / 2, immediate: true })
  }, [api])

  const bind = useDrag(
    ({ down, offset: [ox, oy], movement: [mx] }) => {
      const currentValue = Math.round(ox)
      const displayValue = convertRange(currentValue, sliderWidth, 0, max, min)

      const k = (displayValue % snapInterval) / snapInterval
      const snappedValue = k < 0.5 ? k * -snapInterval : (1 - k) * snapInterval
      spring.val.set(Math.round(displayValue + snappedValue))

      if (!down) {
        setValue(Math.round(displayValue + snappedValue))
      }

      return api.start({ x: ox, immediate: down })
    },
    {
      axis: 'x',
      from: () => [x.get(), 0],
      bounds: { left: 0, right: sliderWidth, top: 0, bottom: 0 },
      rubberband: false,
    }
  )

  const handleHeight = 30

  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        {prefix && <p className="mr-2 font-weight-bold">{prefix}</p>}

        <animated.p className="font-weight-bold">{spring.val.to((val) => Math.round(val))}</animated.p>
        {postfix && <p className="ml-2 font-weight-bold">{postfix}</p>}
      </div>

      <div className="position-relative  " style={{ width: sliderWidth }}>
        <div
          className="position-absolute w-100"
          style={{ height: 2, top: Math.round(handleHeight / 2), left: 0, background: '#9A9A9A', zIndex: 0 }}
        ></div>
        <animated.div
          className="rounded-circle noselect position-relative"
          {...bind()}
          style={{
            left: -handleHeight / 2,
            width: handleHeight,
            height: handleHeight,
            background: PRIMARY_COLOR,
            cursor: 'grab',
            border: '3px solid white',
            x,
          }}
        />
      </div>
      <p className="text-center p-small mt-2">{label}</p>
      {error && (
        <p className="text-center p-small text-danger mt-2" style={{ fontSize: '.7rem' }}>
          {error.message}
        </p>
      )}
    </div>
  )
}
export default Slider

import './styles/styles.scss'
import { AnimatedRoute } from './components/Navigators/AnimatedRoute'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import FrontPage from './pages/FrontPage'
import SpinnerPage from './pages/SpinnerPage'
import Header from './components/Header'

import { ReactComponent as PeggyLogo } from './assets/svg/peggy-logo.svg'
import ResultPageVsMode from './pages/ResultPageVsMode'
import React from 'react'
import ResultPage from './pages/ResultPage'

function App() {
  return (
    <>
      <div className="position-fixed" style={{ bottom: 5, right: 5, zIndex: 110 }}>
        <PeggyLogo></PeggyLogo>
      </div>
      <div className="h-100 ">
        <Router>
          <Header></Header>
          <AnimatedRoute className="position-relative ">
            <Route
              exact
              path={'/:lang'}
              render={(props) => {
                if (props.location.pathname === '/da' || props.location.pathname === '/en') {
                  return <FrontPage></FrontPage>
                }

                return <Redirect to="/en" />
              }}
            ></Route>

            <Route exact path={'/:lang/calculating'}>
              <SpinnerPage></SpinnerPage>
            </Route>

            <Route path={'/:lang/result/:id/vs/:vsid'}>
              <ResultPageVsMode></ResultPageVsMode>
            </Route>

            <Route path={'/:lang/result/:id'}>
              <ResultPage></ResultPage>
            </Route>

            <Redirect from="/result/:id" to="/en/result/:id" />
            <Redirect from="/calculating" to="/:lang/calculating" />
            <Redirect to="/en" />
          </AnimatedRoute>
        </Router>
      </div>
    </>
  )
}

export default App

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/09/2021.
 */
import React, { useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'

const LoadingText = ({ LANGUAGE_TEXT }) => {
  const NUM_TRANS = LANGUAGE_TEXT.spinnerPage.labels
  const [count, setCount] = useState(0)

  const transitions = useTransition(NUM_TRANS[count], {
    from: { opacity: 0, transform: `translate3d(-100px,0px,0px)` },
    enter: { opacity: 1, transform: 'translate3d(0px,0px,0px)' },
    leave: { opacity: 0, transform: `translate3d(100px,0px,0px)` },
  })

  useEffect(() => {
    const id = setTimeout(() => {
      //setItems(NUM_TRANS)
      const newCount = count === NUM_TRANS.length - 1 ? 0 : count + 1
      setCount(newCount)
    }, 1000)

    return () => {
      clearTimeout(id)
    }
  }, [count, NUM_TRANS.length])

  return (
    <div className="" style={{ position: 'relative' }}>
      {transitions((props, item) => {
        return (
          <animated.div className="position-absolute" style={{ top: 0, left: -350, width: 700, ...props }}>
            <h1 className="mt-4 display-3 text-center font-weight-bold" style={{ opacity: 0.2 }}>
              {item}
            </h1>
          </animated.div>
        )
      })}
    </div>
  )
}
export default LoadingText

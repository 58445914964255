/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15-09-2020.
 */
import React, { useCallback, useState } from 'react'

import { useDropzone } from 'react-dropzone'
import Spinner from './spinner/Spinner'

const UploadBtn = ({ label = 'UPLOAD IMAGE', setImage, error, hasFile, children }) => {
  const [isLoading] = useState(false)

  /*const uploadImages = (arr) => {
    const data = new FormData()
    arr.forEach((item, index) => {
      // asset is the name multer expect ---
      data.append('asset', item)
    })

    setImage(arr[0])
  }*/

  const onDrop = useCallback(
    (acceptedFiles) => {
      const arr = acceptedFiles.map((item) => {
        let uuIdBase = new Date().getTime()
        item.uuid = uuIdBase
        return item
      })

      const data = new FormData()
      arr.forEach((item, index) => {
        // asset is the name multer expect ---
        data.append('asset', item)
      })

      setImage(arr[0])
      //uploadImages(cuedImages.concat(arr))
      //uploadImages([].concat(arr))
    },
    [setImage]
  )

  const { getRootProps, getInputProps /*isDragActive*/ } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className="pointer h-100 w-100" style={{ outline: 'none' }}>
      {!isLoading ? (
        <>
          <input {...getInputProps()} />
          {children}
          {error && (
            <p className="text-center p-small text-danger mt-2" style={{ fontSize: '.7rem' }}>
              {error.message}
            </p>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center" style={{ width: '6rem' }}>
          <Spinner size={5}></Spinner>
        </div>
      )}
    </div>
  )
}
export default UploadBtn
//mx-4 pr-4

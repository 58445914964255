/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/09/2021.
 */
import React, { useEffect, useState } from 'react'
import SelectBox from '../components/SelectBox'
import { DEFAULT_COLOR, getLanguage, PRIMARY_COLOR } from '../constants'
import UploadBtn from '../components/UploadBtn'
import { projectStore, SINGLE_MODE } from '../store/ProjectStore'
import { Redirect, useParams } from 'react-router-dom'
import { BlobImage } from '../components/BlobImage'
import Slider from '../components/Slider'

import { ReactComponent as UploadIcon } from '../assets/svg/upload.svg'
import Btn from '../components/Btn'
import PopUpInfo from '../components/popup/PopUpInfo'
import Switch from '../components/Switch'

const itemStyle = {
  background: 'white',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
}

const FrontPage = (props) => {
  const params = useParams()

  const { lang } = params
  const LANGUAGE_TEXT = getLanguage(lang)

  //const [mode, setMode] = useState(SINGLE_MODE)

  const [toPredict, setToPredict] = useState(false)
  const [hasErrors, setErrors] = useState([])

  const mode = projectStore((state) => state.mode)

  const clearPrediction = projectStore((state) => state.clearPrediction)
  const validate = projectStore((state) => state.validate)
  const setIndustry = projectStore((state) => state.setIndustry)
  const setProduct = projectStore((state) => state.setProduct)
  const setWeek = projectStore((state) => state.setWeek)
  const setPosters = projectStore((state) => state.setPosters)
  const setImage = projectStore((state) => state.setImage)
  const setImageVsMode = projectStore((state) => state.setImageVsMode)

  const image = projectStore((state) => state.image)
  const vsModeImage = projectStore((state) => state.vsModeImage)
  const week = projectStore((state) => state.week)
  const industry = projectStore((state) => state.industry)
  const poster = projectStore((state) => state.poster)
  const product = projectStore((state) => state.product)

  const setWeekBoth = (value) => {
    setWeek(value, null)
  }

  const setPosterBoth = (value) => {
    setPosters(value, null)
  }

  const setProductBoth = (value) => {
    setProduct(value, null)
  }

  /*const selectedIndustri = !industry
    ? null
    : LANGUAGE_TEXT.dropdowns.industry.data.filter((item) => item.value === industry)[0]*/

  const selectedProduct = !product
    ? null
    : LANGUAGE_TEXT.dropdowns.product.data.filter((item) => item.value === product)[0]

  useEffect(() => {
    clearPrediction()
  }, [clearPrediction])

  useEffect(() => {
    if (hasErrors.length === 0) {
      //setFirst(false)
      return
    }
    const errors = validate(LANGUAGE_TEXT)

    setErrors(errors)
  }, [image, week, industry, poster, product, LANGUAGE_TEXT, hasErrors.length, validate])

  const errorKeys =
    hasErrors.length !== 0 &&
    hasErrors.reduce((acc, item) => {
      acc[item.type] = item
      return acc
    }, {})

  const startPredicting = () => {
    const errors = validate(LANGUAGE_TEXT)

    if (errors.length === 0) {
      console.log(' FrontPage > SET SET PREDICT = ')
      setToPredict(true)
      return
    }
    setErrors(errors)
  }

  const sortedIndustry = LANGUAGE_TEXT.dropdowns.industry.data.concat([]).sort((a, b) => {
    var nameA = a.title.toLowerCase(),
      nameB = b.title.toLowerCase()
    if (nameA < nameB)
      //sort string ascending
      return -1
    if (nameA > nameB) return 1
    return 0 //default return value (no sorting)
  })

  //
  // the industry selector has been removed.
  // we need to use a const - but we are waiting for Andreas to provide which value
  //
  useEffect(() => {
    const preselectedIndustry = sortedIndustry[5]
    setIndustry(preselectedIndustry)
  }, [setIndustry, sortedIndustry])

  if (toPredict) {
    return <Redirect to={`/${lang}/calculating`}></Redirect>
  }

  return (
    <div className="container d-flex flex-column justify-content-center " style={{ minHeight: '85vh' }}>
      <div className="row ">
        <div className="col-12 mt-5 mb-3 ">
          <h1 className="font-weight-bold display-4 text-center mb-0">{LANGUAGE_TEXT.frontPage.headline}</h1>
        </div>

        <div className="col-7 mx-auto mb-4 ">
          <p className="text-center mb-0">{LANGUAGE_TEXT.frontPage.body}</p>
        </div>

        <div className="col-7 mx-auto d-flex justify-content-center mb-5 mt-3">
          <Switch labelLeft={LANGUAGE_TEXT.typeSwitch.left} labelRight={LANGUAGE_TEXT.typeSwitch.right}></Switch>
        </div>
      </div>

      {/*<div className="col-12 col-lg-4 mb-4 h-100">
              <div className="h-100 d-flex justify-content-center align-items-center" style={itemStyle}>
                {image ? (
                  <BlobImage file={image}></BlobImage>
                ) : (
                  <UploadBtn hasFile={image} error={errorKeys['image']} setImage={setImage}>
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <div className="d-flex justify-content-end p-2">
                        <PopUpInfo text={LANGUAGE_TEXT.dropdowns.image.info}></PopUpInfo>
                      </div>
                      <div className="d-flex justify-content-center pb-6">
                        <p className="font-weight-bold mb-0 pr-2">{LANGUAGE_TEXT.dropdowns.image.title}</p>
                        <UploadIcon style={{ height: '1.5rem', width: '1.5rem' }}></UploadIcon>
                      </div>
                      <div></div>
                    </div>
                  </UploadBtn>
                )}
              </div>
              {image && (
                <UploadBtn error={errorKeys['image']} setImage={setImage}>
                  <div className="d-flex justify-content-center mt-2 position-relative" style={{ zIndex: 100 }}>
                    <div className="d-flex">
                      <p className="font-weight-bold mb-0 pr-2 p-small pointer">Select another image </p>
                      <UploadIcon style={{ height: '1.5rem', width: '1.5rem' }}></UploadIcon>
                    </div>
                  </div>
                </UploadBtn>
              )}
            </div>*/}

      <div className="row " style={{}}>
        {mode === SINGLE_MODE ? (
          <ImageComp
            image={image}
            className="col-12 mb-4"
            setImage={setImage}
            errorKeys={errorKeys}
            LANGUAGE_TEXT={LANGUAGE_TEXT}
          ></ImageComp>
        ) : (
          <>
            <ImageComp
              image={image}
              setImage={setImage}
              errorKeys={errorKeys}
              LANGUAGE_TEXT={LANGUAGE_TEXT}
            ></ImageComp>

            <ImageComp
              image={vsModeImage}
              setImage={setImageVsMode}
              errorKeys={errorKeys}
              LANGUAGE_TEXT={LANGUAGE_TEXT}
            ></ImageComp>
          </>
        )}
      </div>

      <div className="row" style={{}}>
        {/*<div className="col-3 ">
          <div style={itemStyle} className="h-100 d-flex flex-column justify-content-between ">
            <div className="d-flex justify-content-end p-2">
              <PopUpInfo text={LANGUAGE_TEXT.dropdowns.industry.info}></PopUpInfo>
            </div>
            <div className="pb-4">
              <SelectBox
                initialValue={LANGUAGE_TEXT.dropdowns.industry.title}
                preSelected={selectedIndustri}
                items={sortedIndustry}
                setValue={setIndustryTest}
                error={errorKeys['industry']}
              ></SelectBox>
            </div>

            <div></div>
          </div>
        </div>*/}
        <div className="col-4  ">
          <div className="h-100 d-flex flex-column justify-content-between " style={itemStyle}>
            <div className="d-flex justify-content-end p-2">
              <PopUpInfo position="left top" text={LANGUAGE_TEXT.dropdowns.product.info}></PopUpInfo>
            </div>
            <div className="pb-4">
              <SelectBox
                preSelected={selectedProduct}
                initialValue={product[0] || LANGUAGE_TEXT.dropdowns.product.title}
                items={LANGUAGE_TEXT.dropdowns.product.data}
                setValue={setProductBoth}
                error={errorKeys['product']}
              ></SelectBox>
            </div>

            <div></div>
          </div>
        </div>
        <div className="col-4 ">
          <div className="h-100 d-flex flex-column justify-content-between " style={itemStyle}>
            <div className="d-flex justify-content-end p-2">
              <PopUpInfo text={LANGUAGE_TEXT.dropdowns.week.info}></PopUpInfo>
            </div>
            <div className="d-flex justify-content-center pb-6">
              <Slider
                setValue={setWeekBoth}
                prefix={LANGUAGE_TEXT.dropdowns.week.prefix}
                postfix={LANGUAGE_TEXT.dropdowns.week.postfix}
                min={LANGUAGE_TEXT.dropdowns.week.min}
                max={LANGUAGE_TEXT.dropdowns.week.max}
                snapInterval={LANGUAGE_TEXT.dropdowns.week.snap}
                initialValue={Number(week[0]) || LANGUAGE_TEXT.dropdowns.week.initialValue}
                label={LANGUAGE_TEXT.dropdowns.week.label}
                error={errorKeys['week']}
              ></Slider>
            </div>
            <div></div>
          </div>
        </div>
        <div className="col-4 ">
          <div className="h-100 d-flex flex-column justify-content-between " style={itemStyle}>
            <div className="d-flex justify-content-end p-2">
              <PopUpInfo position="left top" text={LANGUAGE_TEXT.dropdowns.posters.info}></PopUpInfo>
            </div>
            <div className="d-flex justify-content-center pb-6">
              <Slider
                setValue={setPosterBoth}
                prefix={LANGUAGE_TEXT.dropdowns.posters.prefix}
                postfix={LANGUAGE_TEXT.dropdowns.posters.postfix}
                min={LANGUAGE_TEXT.dropdowns.posters.min}
                max={LANGUAGE_TEXT.dropdowns.posters.max}
                snapInterval={LANGUAGE_TEXT.dropdowns.posters.snap}
                initialValue={Number(poster[0]) || LANGUAGE_TEXT.dropdowns.posters.initialValue}
                label={LANGUAGE_TEXT.dropdowns.posters.label}
                error={errorKeys['poster']}
              ></Slider>
            </div>
            <div></div>
          </div>
        </div>

        <div className="col-12 mt-4 mb-5 d-flex justify-content-center">
          <Btn
            onClick={startPredicting}
            background={PRIMARY_COLOR}
            label={LANGUAGE_TEXT.frontPage.startBtnLabel}
            width={240}
            style={{ background: validate(LANGUAGE_TEXT).length === 0 ? PRIMARY_COLOR : DEFAULT_COLOR }}
          ></Btn>
        </div>
      </div>
    </div>
  )
}
export default FrontPage

const ImageComp = ({ image, setImage, errorKeys, LANGUAGE_TEXT, className = 'col-6 mb-4' }) => {
  return (
    <div className={className}>
      <div className="h-100 d-flex justify-content-center align-items-center" style={{ minHeight: 300, ...itemStyle }}>
        {image ? (
          <BlobImage file={image}></BlobImage>
        ) : (
          <UploadBtn hasFile={image} error={errorKeys['image']} setImage={setImage}>
            <div className="h-100 d-flex flex-column justify-content-between">
              <div className="d-flex justify-content-end p-2">
                {/*<PopUpInfo text={LANGUAGE_TEXT.dropdowns.image.info}></PopUpInfo>*/}
              </div>
              <div className="d-flex justify-content-center pb-6 mt-3 align-items-center">
                <UploadIcon style={{ height: '1.8rem', width: '1.8rem' }}></UploadIcon>
                <div className="ml-3">
                  <p className="font-weight-bold mb-0 pr-2">{LANGUAGE_TEXT.dropdowns.image.title}</p>
                  <p className="p-small">{LANGUAGE_TEXT.dropdowns.image.type}</p>
                </div>
              </div>
              <div></div>
            </div>
          </UploadBtn>
        )}
        {image && (
          <div className="position-absolute" style={{ background: 'white', bottom: 6, right: 20 }}>
            <UploadBtn error={errorKeys['image']} setImage={setImage}>
              <div className="d-flex justify-content-center mt-2 position-relative" style={{ zIndex: 100 }}>
                <div className="d-flex pl-3 pr-3 py-1">
                  <p className="font-weight-bold mb-0 pr-2 p-small pointer">Select another image </p>
                  <UploadIcon style={{ height: '1.5rem', width: '1.5rem' }}></UploadIcon>
                </div>
              </div>
            </UploadBtn>
          </div>
        )}
      </div>

      {/*<div className="my-2">
        <h4>asdasd</h4>
        <h4>asdasd</h4>
      </div>*/}
    </div>
  )
}

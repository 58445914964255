/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/12/2021.
 */
import React from 'react'
import InfoIcon from '../../assets/svg/info.svg'

import Popup from 'reactjs-popup'
import './info-popover.scss'

const PopUpInfo = ({
  size = 20,
  position = 'bottom',
  text = { head: 'headline', body: 'body text' },
  head = 'headline',
}) => {
  const headlineText = text.head
  const bodyText = text.body

  return (
    <Popup
      className="info-popup"
      trigger={
        <div className="position-relative">
          <div className="rounded-circle  " style={{ height: size, width: size }}>
            <img className="w-100 h-100" src={InfoIcon} alt="" />
          </div>
        </div>
      }
      position={position}
      on={['hover', 'focus']}
      closeOnDocumentClick
    >
      <div className="p-2" style={{}}>
        <h5 style={{ color: 'black' }}>{headlineText}</h5>
        <p className="p-small " style={{ whiteSpace: 'pre-line' }}>
          {bodyText}
        </p>
      </div>
    </Popup>
  )
}
export default PopUpInfo

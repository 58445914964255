//@ts-ignore
import create from 'zustand'
import { ApiPostForm, getData, post } from '../service/API'
import { CALCULATING } from '../constants'

export const SINGLE_MODE = 'SINGLE'
export const VS_MODE = 'VS_MODE'

export const projectStore = create((set, get) => {
  return {
    language: '',
    industry: null,
    product: [null, null],
    week: [null, null],
    poster: [null, null],
    image: null,
    vsModeImage: null,
    mode: VS_MODE,
    //
    // the result of the predict function
    //
    predictionResult: null,
    predictionResultTwo: null,

    //
    // indicates the state of the prediction
    //
    processing: false,
    processingVsMode: false,

    toggleMode: () => {
      const oldMode = get().mode
      const newMode = oldMode === SINGLE_MODE ? VS_MODE : SINGLE_MODE
      set({ mode: newMode })
    },

    setProcessing: (state) => {
      set({ processing: state })
    },

    setIndustry: (industry) => {
      //
      // since we have english and danish we use the industry.value which should be sent to the backend
      //
      set({ industry: industry.value })
    },

    setProduct: (productObj, index = null) => {
      //
      // since we have english and danish we use the product.value which should be sent to the backend
      //

      const currentProduct = get().product

      if (index === null) {
        set({ product: [productObj.value, productObj.value] })
        return
      }

      currentProduct[index] = productObj.value
      set({ product: currentProduct })
    },

    setWeek: (week, index = null) => {
      const currentWeek = get().week

      if (index === null) {
        set({ week: [week, week] })
        return
      }

      currentWeek[index] = week
      set({ week: currentWeek })
    },

    setPosters: (poster, index) => {
      const currentPoster = get().poster
      console.log(' ProjectStore > poster = ', poster, index)

      if (index === null) {
        set({ poster: [poster, poster] })
        return
      }

      currentPoster[index] = poster
      set({ poster: currentPoster })
    },

    setImage: (image) => {
      set({ image: image })
    },

    setImageVsMode: (image) => {
      set({ vsModeImage: image })
    },

    // called from frontpage
    clearPrediction: () => {
      set({ predictionResult: null, predictionResultTwo: null, processing: false, processingVsMode: false })
    },

    setLanguage: (lang) => {
      set({ language: lang })
    },

    validate: (LANGUAGE_TEXT) => {
      const industry = get().industry
      const product = get().product
      const week = get().week
      const poster = get().poster
      const image = get().image
      const vsModeImage = get().vsModeImage

      const mode = get().mode

      const errors = []

      if (!industry) {
        errors.push({ type: 'industry', message: LANGUAGE_TEXT.dropdowns.industry.errorText })
      }
      if (!product[0]) {
        errors.push({ type: 'product', message: LANGUAGE_TEXT.dropdowns.product.errorText })
      }
      if (!week[0]) {
        errors.push({ type: 'week', message: LANGUAGE_TEXT.dropdowns.week.errorText })
      }

      if (!poster[0]) {
        errors.push({ type: 'poster', message: LANGUAGE_TEXT.dropdowns.posters.errorText })
      }

      if (!image) {
        errors.push({ type: 'image', message: LANGUAGE_TEXT.dropdowns.image.errorText })
      }

      if (mode === VS_MODE && !vsModeImage) {
        errors.push({ type: 'image', message: LANGUAGE_TEXT.dropdowns.image.errorText })
      }

      return errors
    },

    //"anon8018fc60c8eaf868be848decb7ed195b9425a1e3"
    //https://afa.createwithpeggy.com/project?id=anon96d04a6530b33e9dc93ccf0c41e25f171727f9b4
    loadProject: (id) => {
      console.log(' ProjectStore > id = ', id)
      const p = '/project?id=' + id //'anon8018fc60c8eaf868be848decb7ed195b9425a1e3'
      return getData(p)
        .then((result) => {
          console.log(' ProjectStore > result = ', result)
          if (result && result.data && result.data.length === 0) {
            Promise.reject('No result was found')
            return
          }

          const prediction = result.data[0]

          set({
            processing: 'DONE',
            week: [prediction.inputs.weekStart, get().week[1]],
            industry: prediction.inputs.industry,
            product: [prediction.inputs.product, get().product[1]],
            poster: [prediction.inputs.posters, get().poster[1]],
            predictionResult: prediction,
          })
        })
        .catch((error) => {
          console.log(' ProjectStore > error = ', error)
          //alert('Something bad happen  ')
        })
    },

    loadProjectTwo: (id) => {
      console.log(' ProjectStore > id = ', id)
      const p = '/project?id=' + id //'anon8018fc60c8eaf868be848decb7ed195b9425a1e3'
      return getData(p)
        .then((result) => {
          console.log(' ProjectStore > result = ', result)
          if (result && result.data && result.data.length === 0) {
            Promise.reject('No result was found')
            return
          }

          const prediction = result.data[0]

          set({
            processingVsMode: 'DONE',
            week: [get().week[0], prediction.inputs.weekStart],
            industry: prediction.inputs.industry,
            product: [get().product[0], prediction.inputs.product],
            poster: [get().poster[0], prediction.inputs.posters],
            predictionResultTwo: prediction,
          })
        })
        .catch((error) => {
          console.log(' ProjectStore > error = ', error)
          //alert('Something bad happen  ')
        })
    },

    predict: () => {
      console.log(' ProjectStore > STARTING PRDICT FUNCTION = ')

      const processing = get().processing

      if (processing === CALCULATING) {
        console.log(' ProjectStore > DONT START AGAIN = ')
        return
      }

      const industry = get().industry
      const product = get().product[0]
      const week = get().week[0]
      const poster = get().poster[0]
      const image = get().image
      const validate = get().validate

      const errors = validate()

      if (errors.length > 0) {
        return errors
      }

      const data = new FormData()
      data.append('asset', image)
      data.append('industry', industry)
      data.append('product', product)
      data.append('posters', poster)
      //data.append('coverage', 500)
      //data.append('frequency', 500)
      data.append('weekStart', week)

      set({ processing: CALCULATING })
      ApiPostForm('/imagePredict', data).then(
        (res) => {
          if (res.result === false) {
            alert('Images could not be uploaded')
            return { result: false }
          }

          if (res.data && res.data.length === 0) {
            console.log(' ProjectStore > res = ', res)
            return Promise.reject('Response data did not contain any data ')
          }

          console.log(' ProjectStore > PREDICT ONE DONE = ', res)
          const prediction = res.data[0]

          set({ processing: 'DONE', predictionResult: prediction })

          return res
        },
        (err) => {
          alert(err)
          console.log(' UploadPage > err = ', err)
        }
      )
    },

    predictImageTwo: () => {
      console.log(' ProjectStore > STARTING PREDICT IMAGE TWO = ')

      const processing = get().processingVsMode

      if (processing === CALCULATING) {
        console.log(' ProjectStore >predictImageTwo DONT START AGAIN = ')
        return
      }

      const industry = get().industry
      const product = get().product[1]
      const week = get().week[1]
      const poster = get().poster[1]
      const image = get().vsModeImage
      const validate = get().validate

      const errors = validate()

      if (errors.length > 0) {
        return errors
      }

      const data = new FormData()
      data.append('asset', image)
      data.append('industry', industry)
      data.append('product', product)
      data.append('posters', poster)
      //data.append('coverage', 500)
      //data.append('frequency', 500)
      data.append('weekStart', week)

      set({ processingVsMode: CALCULATING })

      ApiPostForm('/imagePredict', data).then(
        (res) => {
          if (res.result === false) {
            alert('Images could not be uploaded')
            return { result: false }
          }

          if (res.data && res.data.length === 0) {
            console.log(' ProjectStore > res = ', res)
            return Promise.reject('Response data did not contain any data ')
          }

          console.log(' ProjectStore > predictImageTwo DONE ')
          const prediction = res.data[0]

          set({ processingVsMode: 'DONE', predictionResultTwo: prediction })

          return res
        },
        (err) => {
          alert(err)
          console.log(' UploadPage > err = ', err)
        }
      )
    },

    regenerate: (imagePath) => {
      const processing = get().processing

      set({ processing: CALCULATING })

      if (processing === CALCULATING) {
        //console.log(' ProjectStore > DONT REGENERATE  AGAIN = ')
        return
      }

      console.log(' ProjectStore > RUN regenerate ONE ')
      const industry = get().industry
      const product = get().product[0]
      const week = get().week[0]
      const poster = get().poster[0]

      const obj = {
        industry: industry,
        product: product,
        weekStart: week,
        posters: poster,
        /*coverage: 500,
        frequency: 500,*/
        imageName: imagePath,
      }

      console.log(' ProjectStore > REGEN ONE SENT = ', obj)
      return post('/predict', obj)
        .then((result) => {
          console.log('regene result = ', result)
          if (result.data.length === 0) {
            return Promise.reject('Response data did not contain any data ')
          }

          const prediction = result.data[0]
          set({ processing: 'DONE', predictionResult: prediction })
          //return result
        })
        .catch((error) => {
          console.log('regenerate = ', error)
          return error
        })
    },

    regenerateTwo: (imagePath) => {
      const processingVsMode = get().processingVsMode

      set({ processingVsMode: CALCULATING })

      if (processingVsMode === CALCULATING) {
        //console.log(' ProjectStore > DONT REGENERATE  AGAIN = ')
        return
      }

      console.log(' ProjectStore > RUN regenerateTwo ')
      const industry = get().industry
      const product = get().product[1]
      const week = get().week[1]
      const poster = get().poster[1]

      const obj = {
        industry: industry,
        product: product,
        weekStart: week,
        posters: poster,
        /*coverage: 500,
        frequency: 500,*/
        imageName: imagePath,
      }
      console.log(' ProjectStore > REGEN TWO SENT = ', obj)
      return post('/predict', obj)
        .then((result) => {
          console.log('regenerateTwo result = ', result)
          if (result.data.length === 0) {
            return Promise.reject('Response data did not contain any data ')
          }

          const prediction = result.data[0]
          set({ processingVsMode: 'DONE', predictionResultTwo: prediction })
          //return result
        })
        .catch((error) => {
          console.log('regenerate = ', error)
          return error
        })
    },
  }
})
